const Countdown = ({days, hours, minutes, seconds}) => {

  return <>
    <div className="c-timer-wrapper">
        <div className="timer">
          <div className="timer-item">
            <p className="timer-title">{days<10? `0${days}`:days}</p>
            <p className="timer-subtitle">Days</p>
          </div>
          <div className="timer-item">
            <p className="timer-title">{hours<10? `0${hours}`:hours}</p>
            <p className="timer-subtitle">Hours</p>
          </div>
          <div className="timer-item">
            <p className="timer-title">{minutes<10? `0${minutes}`:minutes}</p>
            <p className="timer-subtitle">Minutes</p>
          </div>
          <div className="timer-item">
            <p className="timer-title">{seconds<10? `0${seconds}`:seconds}</p>
            <p className="timer-subtitle">Seconds</p>
          </div>
        </div>
    </div>
  </>
}

Countdown.defaultProps = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
}

export default Countdown