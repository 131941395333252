import { Countdown, Footer, Navbar, NFTMintX, SubBanner } from "components";
import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import imageUrl from "helpers/imageUrl";

const NFTMint = () => {
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const targetDate = new Date("December 25,2022").getTime();

    interval = setInterval(() => {
      const currentDate = new Date().getTime();

      const duration = targetDate - currentDate;

      const days = Math.floor(duration / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((duration % (1000 * 60)) / 1000);

      if (duration < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  return (
    <>
      <div className="nft-mint-page">
        <SubBanner
          img={"nft-mint-img.png"}
          titleL={"Acquire your Membership"}
          titleR={"Pass Today!"}
          br
        />
        <Container fluid className="custom-container">
          <Countdown
            days={timerDays}
            hours={timerHours}
            minutes={timerMinutes}
            seconds={timerSeconds}
          />
        </Container>
        <NFTMintX />
        <Footer />
      </div>
    </>
  );
};

export default NFTMint;
