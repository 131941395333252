import imageUrl from "helpers/imageUrl";
import React from "react";
import { TeamCard } from "./";
import Slider from "react-slick";



function PrimaryNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <div className="primary-slider-arrow" onClick={onClick}><img src={imageUrl('arrow-right.png')} alt="" className="icon-larger"/></div>
    </div>
  );
}

function PrimaryPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
     <div className="primary-slider-arrow" onClick={onClick}><img src={imageUrl('arrow-left.png')} alt="" className="icon-larger"/></div>
    </div>
  );
}


const Team = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],

    nextArrow: <PrimaryNextArrow />,
    prevArrow: <PrimaryPrevArrow />,
  };


  return (
    <>
      <div className="team-wrapper container-fluid custom-container py-5">
        <div className="sub-section-wrapper">
          <div className="sub-section-text center">
            <p className="sub-section-title">
              OUR CORE <span className="golden-magazine">TEAM</span>
            </p>
            <p className="normal-p">
              Lorem ipsum dolor sit amet consectetur. Proin sagittis augue
              malesuada aenean sed.
            </p>
          </div>
        </div>
        <div className="section-body">
          <Slider {...settings}>
              <TeamCard tImg={"team-1.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-2.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-3.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"gallery-4.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-5.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-1.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-2.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-3.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"gallery-4.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
              <TeamCard tImg={"team-5.png"} tName={"Yasmina Guem"} tTitle={"Head of Social Media"}/>
          </Slider>
          <div className="flex-cards">

          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
