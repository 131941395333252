import imageUrl from 'helpers/imageUrl'
import React from 'react'

const NFTCard = ({nftImg, nftTitle, nftProfile, nftPerson, nftBid}) => {
  return <>
  <div className="nft-card  mb-3 custom-card">
    <div className="nft-card-img">
      <img src={imageUrl(nftImg)} alt="" />
    </div>
  </div>
  </>
}

export default NFTCard