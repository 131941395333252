// import { ethers } from "ethers";
import imageUrl from "helpers/imageUrl";
import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
const Navbar = ({isOpen, setIsOpen}) => {
  const [currentAccount, setCurrentAccount] = useState();
  const [loading, setLoading] = useState(false);
  const { ethereum } = window;  

  const checkWalletIsConnected = async () => {
    try {
        if (!ethereum) return alert("Please Install Metamask");
        const accounts = await ethereum.request({ method: "eth_accounts" });
        if (accounts.length) {
            setCurrentAccount(accounts[0]);

        } else {
            console.log("No account found")
        }
  
    } catch (err) {
        // setError(err.message);
        throw new Error("No ethereum object.")
    }
}

 const connectWallet = async () => {

    try {
      setLoading(true)
        if (!ethereum) return alert("Please Install Metamask");
        const accounts = await ethereum.request({ method: "eth_requestAccounts" })
        setCurrentAccount(accounts[0]);
        window.location.reload();
        setLoading(false)
    } 
    
    catch (err) {
        throw new Error("No ethereum object.")
    }
 }

 window.ethereum && window.ethereum.on("accountsChanged", async(account) => {
  setCurrentAccount(account[0]);
  window.location.reload()
})

window.ethereum && ethereum.on("chainChanged", async()=>{
  window.location.reload();
});

const changeNetwork = async () => {
  try {
    setLoading(true)
    if (!ethereum) throw new Error("No crypto wallet found");
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{
        chainId: "0x5"
      //chainId: "0x1"
     }]
    });
   await connectWallet();
   setLoading(false)
  } catch (err) {
    setLoading(false)
    console.log(err.message);
  }
};


useEffect(() => {
  checkWalletIsConnected();
}, [])

  return (
    <>
    <div className={`navbar-wrapper ${isOpen? "dark-bg": "black-bg"}`}>
      <div className="custom-navbar container-fluid custom-container flex-c-b">
        <div className="hamburger" onClick={()=>setIsOpen(!isOpen)}>
          <img src={imageUrl("hamburger.png")} alt=""  width={"40px"} />
        </div>
        <Link to="/" className={`logo`}>
          <img src={imageUrl("logo.png")} alt=""/>
        </Link>
        <div className="navbar-btn">
          <button className="golden-btn" onClick={()=>changeNetwork()} disabled={loading}>
            {currentAccount ? `${currentAccount?.slice(0,5)}...${currentAccount?.slice(currentAccount?.length - 4)}` :"Connect Wallet"}
            <img src={imageUrl('wallet-sky.png')} alt="" className="icon ms-3 sky-icon d-none"/> 
            <img src={imageUrl('wallet-black.png')} alt="" className="icon ms-3 black-icon"/> 
          </button>
          
        </div>
      </div>
    </div>
    </>
  );
};

export default Navbar;
