import imageUrl from "helpers/imageUrl";
import React from "react";

const Header = () => {
  return (
    <>
      <div className="l-header-wrapper">
          <div className="l-header flex-c-c h-50">
            <div className="section-text">
              <p className="section-title">THE HIGH CLUB</p>
              <p className="section-subtitle text-center">Get access to your luxury <br /> membership pass today</p>
            </div>
            <div className="banner-characters">
                <img src={imageUrl('banner-characters.png')} alt="" className="" width={"100%"}/>
                <img src={imageUrl('half-shadow.png')} alt="" className="banner-shadow" />
            </div>
          </div>
      </div>
    </>
  );
};

export default Header;
