import imageUrl from "helpers/imageUrl";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const SubBanner = ({ img, titleL, titleR, br }) => {
  return (
    <>
      <div className="sub-banner">
        <Container fluid className="custom-container">
          <Row>
            <Col md={6} className="d-flex align-items-center position-relative">
              <p className="section-title">
                {titleL}
                {br && <br />}
                <span className="golden-magazine">{titleR}</span>
                <img src={imageUrl('banner-dot-1.png')} alt="" className="position-absolute banner-dot-1"/>
                <img src={imageUrl('banner-dot-2.png')} alt="" className="position-absolute banner-dot-2"/>
                <img src={imageUrl('banner-dot-3.png')} alt="" className="position-absolute banner-dot-3"/>
              </p>
            </Col>
            <Col md={6} className="d-flex align-items-center pe-0">
              <div className="sub-banner-image position-relative">
                <img src={imageUrl(img)} alt="" width={"100%"} height={"100%"}/>
                <img src={imageUrl('half-shadow.png')} alt="" className="position-absolute start-0 w-100 bottom-0"/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SubBanner;
