import { Navbar, NavMenu } from 'components';
import { Routes, Route } from 'react-router-dom'
import { LandingPage, NFTMintPage } from 'screens';
import { useState } from 'react';

const App = () => {

  const [isOpen, setIsOpen] = useState(false)

  return(
    <>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen}/>
      {isOpen && <NavMenu />}
      <Routes>
        <Route path='/' element={<LandingPage />}/>
        <Route path='/nftmint' element={<NFTMintPage />}/>

      </Routes>
    </>
  )
}

export default App;
