import imageUrl from "helpers/imageUrl";
import React from "react";

const UtilityCard = ({uImg, uTitle, uDesc, uDesc2}) => {
  return (
    <>
      <div className="u-card mb-3 custom-card">
        <div className="u-card-img">
          <img src={imageUrl(uImg)} alt="" />
        </div>
        <div className="u-card-text">
          <p className="card-title">{uTitle}</p>
          <p className="normal-p">
            {uDesc}
            <div className="mb-2"></div>
            {uDesc2}
          </p>
        </div>
      </div>
    </>
  );
};

export default UtilityCard;
