import imageUrl from "helpers/imageUrl";
import React from "react";

const ProductShow = () => {
  return (
    <>
      <div className="sub-section-wrapper container-fluid custom-container">
        <div className="sub-section-text">
          <p className="sub-section-title">
            OUR PRODUCT <br />{" "}
            <span className="golden-magazine">HIGH PORTAL</span>
          </p>
          <p className="normal-p">
            Our holders portal is the heartbeat of our community experience,
            where you will be able to get all relevant information, discounts
            and updates.
            <ol className="ps-5 pt-2 text-start">
              <li>A Get extensive information about our Cannabis farm in Thailand</li>
              <li>Access future developments of The High Club</li>
              <li>Members directory, network with other members</li>
              <li>Exclusive access to private IRL parties and events</li>
              <li>Global perks from wellbeing resorts & hotels</li>
            </ol>
          </p>
        </div>
        {/* <div className="product-show-img">
          <img src={imageUrl("product.png")} alt="" width={"100%"} />
        </div> */}
      </div>
    </>
  );
};

export default ProductShow;
