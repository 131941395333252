import imageUrl from 'helpers/imageUrl'
import React from 'react'
import {Row, Col} from 'react-bootstrap'

const ContactForm = () => {
  return <>
    <div className="contact-form-wrapper container-fluid custom-container pt-5 mt-5">
    <Row className='align-items-center'>
        <Col md={5}>
  
        </Col>
        <Col md={1}></Col>
        <Col md={6}>
            <div className="contact-form-img position-relative d-flex justify-content-center align-items-center">
                <img src={imageUrl('character-5-shield.png')} alt="" width={"100%"}/>
                <img src={imageUrl('half-shadow.png')} alt="" className='position-absolute start-0 w-100 bottom-0'/>
            </div>
        </Col>
    </Row>

    </div>
  </>
}

export default ContactForm