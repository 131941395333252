import imageUrl from "helpers/imageUrl";
import React, { useEffect, useState } from "react";
import { Container, Dropdown, Row, Col } from "react-bootstrap";
import { ethers } from "ethers";
import readXlsxFile from "read-excel-file";
import { contractABI, contractAddress } from "../utils/addressAndABI";
const { ethereum } = window;
const NFTMintX = () => {
  const [addresses, setAddresses] = useState([]);
  const [presalePrice, setPreSalePrice] = useState();
  const [preSale, setpreSale] = useState(false);
  const [sale, setSale] = useState(false);
  const [currentAccount, setCurrentAccount] = useState();
  const [salePrice, setSalePrice] = useState();
  const [userInfo, setUserInfo] = useState([]);
  // const [userMintStatus, setUserMintStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState();
  const [chainID, setChainId] = useState()
  const [balance, setBalance] = useState()

  const checkWalletIsConnected = async () => {
    try {
      if (!ethereum) return alert("Please Install Metamask");
      const accounts = await ethereum.request({ method: "eth_accounts" });
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log("No account found");
      }
    } catch (err) {
      // setError(err.message);
      throw new Error("No ethereum object.");
    }
  };

  const getNFTContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);

    const signer = provider.getSigner();

    const NFtContract = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );

    return NFtContract;
  };

  //////////////////////////////////////////////////Setter Functions/////////////////////////////////////////
  const StartPreSale = async () => {
    try {
      setLoading(true);
      const preSale = await getNFTContract().startPreSale();
      await preSale.wait();
      window.location.reload(); 
      setLoading(false);
    
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const AddWhiteList = async () => {
    try {
      setLoading(true);
      const addWhiteList = await getNFTContract().addWhiteListedUsers(
        addresses
      );
      await addWhiteList.wait();
      window.location.reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const StartSale = async () => {
    try {
      setLoading(true);
      const res = await getNFTContract().startSale();
      await res.wait();
      setSale(res);
      window.location.reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  
  const mintNFT = async () => {
    try {
      setLoading(true);
      const res = await getNFTContract().mintNFT({
        value: preSale ? presalePrice : salePrice,
      });
      await res.wait();
      window.location.reload();
      setLoading(false);
    } catch (err) {
       if (!sale && !preSale) {
        setLoading(false);
        return alert("sale hasn't started yet");
      }
      else if (err.code === "ACTION_REJECTED") {
        setLoading(false);
        return alert("you have rejected the transaction");
      }
      //  else if (userInfo?.status == true || userMintStatus == true) {
      //   setLoading(false);
      //   return alert("you have already Minted NFT");
      // } 
      else if (preSale == true &&
        currentAccount?.toString()?.toLowerCase() !==
        userInfo?.Address?.toString()?.toLowerCase()
      ) {
        setLoading(false);
        return alert("you are not whiteListed user");
      } else {
        setLoading(false);
        return alert("Something Went Wrong, check your account balance or internet connection");
      }
    }
  };

  const handleChange = (e) => {
    setLoading(true);
    let _address = [];
    if (e.target.files[0]) {
      readXlsxFile(e.target.files[0]).then((rows) => {
        rows.forEach((element) => {
          if (element[0].length !== 42)
            return alert("Addresses are not correct");
          console.log("___________________", element[0]);
          let address = ethers.utils.getAddress(element[0]);
          _address.push(address);
        });
      });
    }
    setAddresses(_address);
    setLoading(false);
  };

  const WithDrawAmount = async() => {
    try {
      setLoading(true)
      const res = await getNFTContract().withdrawAmount();
      res.wait();
      setLoading(false);
    }catch(err) {
      console.log(err)
    }
  }

  //////////////////////////////////////////////////Getter Functions/////////////////////////////////////////
  const getpreSalePrice = async () => {
    try {
      const preSalePrice = await getNFTContract().preSalePrice();
      setPreSalePrice(preSalePrice.toString());
    } catch (err) {
      console.log("error", err);
    }
  };

  const getsalePrice = async () => {
    try {
      const SalePrice = await getNFTContract().salePrice();
      setSalePrice(SalePrice.toString());
    } catch (err) {
      console.log("error", err);
    }
  };

  const getWhiteList = async () => {
    try {
      const addWhiteList = await getNFTContract().whiteListed(currentAccount);
      setUserInfo(addWhiteList);
      console.log("WhiteList", addWhiteList.Address);
    } catch (err) {
      console.log("error", err);
    }
  };

  // const getUserMint = async () => {
  //   try {
  //     const res = await getNFTContract().userMint(currentAccount);
  //     setUserMintStatus(res);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  const getPreSaleStatus = async () => {
    try {
      const res = await getNFTContract().preSale();
      setpreSale(res);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getSaleStatus = async () => {
    try {
      const res = await getNFTContract().sale();
      setSale(res);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getOwnerAddress = async () => {
    try {
      const res = await getNFTContract().owner();
      setOwner(res);
    } catch (err) {
      console.log("error", err);
    }
  };

  const GetBalance = async () => {
    try {
      const res = await getNFTContract().getBalance();
      setBalance(res.toString())
    }catch(err) {
      console.log(err)
    }
  }

  console.log("balance",balance)


const getChainId = async() => {
  setChainId(Number(ethereum.chainId).toString());
}

  useEffect(() => {
    const func = async () => {
      await getWhiteList();
      // await getUserMint();
      await getOwnerAddress();
    };
    func();
  }, [currentAccount]);

  useEffect(() => {
    const func = async () => {
      await checkWalletIsConnected();
      await getpreSalePrice();
      await getsalePrice();
      await getPreSaleStatus();
      await getSaleStatus();
      await getChainId();
      await GetBalance();
    };
    func();
  }, []);
  return (
    <>
      <div className="nft-mint-x">
        <Container fluid className="custom-container">
          <div className="sub-section-text">
            <p className="sub-section-title">
              MINT YOUR <span className="golden-magazine">NFT</span>
            </p>
         {chainID == 5 ?   (<div className="flex-btns">
              <button
                className="golden-btn thin-btn"
                onClick={mintNFT}
                disabled={loading}
              >
                Pay With Crypto
              </button>
              {owner?.toString()?.toLowerCase() ==
                currentAccount?.toString()?.toLowerCase() &&
                (!preSale && !sale ? (
                  <button
                    className="golden-btn thin-btn"
                    onClick={StartPreSale}
                    disabled={preSale || loading}
                  >
                    Start PreSale
                  </button>
                ) : (
                  <button
                    className="golden-btn thin-btn"
                    onClick={StartSale}
                    disabled={sale || loading}
                  >
                    Start Sale
                  </button>
                ))}
              <button className="sky-btn thin-btn ms-3" disabled={loading}>
                Pay With Card
              </button>
             {owner?.toString()?.toLowerCase() ==
                currentAccount?.toString()?.toLowerCase() && balance > 0 ? (<button className="sky-btn thin-btn ms-3" disabled={loading} onClick={WithDrawAmount}>
                Withdraw Amount
              </button>) : ""}
              {owner?.toString()?.toLowerCase() ==
                currentAccount?.toString()?.toLowerCase() && (
                <>
                  <input type="file" onChange={handleChange} disabled={loading} />
                  <button
                    className="sky-btn thin-btn ms-3"
                    onClick={AddWhiteList}
                    disabled={loading}
                  >
                    Upload
                  </button>
                </>
              )}
            </div>) : "Switch Your wallet to supported network"}
          </div>
{/* 
          <Row g={0}>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-1.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-6.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-2.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-3.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-7.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-1.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-6.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-2.png")} alt="" />
                </div>
              </div>
            </Col>
            <Col className="col-6" md={4}>
              <div className="mint-item-wrapper pb-3">
                <div className="mint-item">
                  <img src={imageUrl("gallery-3.png")} alt="" />
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default NFTMintX;
