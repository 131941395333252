import React from "react";
import {Col, Row} from 'react-bootstrap'
import {NFTCard} from "components";

const Gallery = () => {
  return (
    <>
      <div className="gallery-wrapper container-fluid custom-container">
        <div className="sub-section-text">
          <p className="sub-section-title">
            OUR <span className="golden-magazine">GALLERY</span>
          </p>
        </div>
        <div className="section-body">
          <Row className="">
            <Col md={6} lg={4}><NFTCard nftImg={'gallery-1.png'}/></Col>
            <Col md={6} lg={4}><NFTCard nftImg={'gallery-2.png'}/></Col>
            <Col md={6} lg={4}><NFTCard nftImg={'gallery-3.png'}/></Col>
          <Col md={6} lg={4}><NFTCard nftImg={'gallery-6.png'}/></Col>
          <Col md={6} lg={4}><NFTCard nftImg={'gallery-4.png'}/></Col>
          <Col md={6} lg={4}><NFTCard nftImg={'gallery-8.png'}/></Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Gallery;
