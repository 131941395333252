import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const NavMenu = () => {
  return (
    <>
      <div className="nav-menu-wrapper">
        <Container fluid className="custom-container">
          <div className="nav-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/nftmint">NFT Mint</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NavMenu;
