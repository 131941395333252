import imageUrl from "helpers/imageUrl";
import React from "react";


const TeamCard = ({tImg, tName, tTitle}) => {
  return (
    <>
    <div className="t-card-item">
      <div className="t-card-wrapper">
        <div className="t-card-img">
          <img src={imageUrl(tImg)} alt="" />
        </div>
        <p className="card-title smaller">{tName}</p>
        <p className="normal-p">{tTitle}</p>
        <div className="card-icons">
          <div className="card-icon-wrapper">
            <div className="card-icon instagram">
              <i class="fa-brands fa-instagram "></i>
            </div>
          </div>
          <div className="card-icon-wrapper">
            <div className="card-icon in">
              <i class="fa-brands fa-linkedin-in"></i>{" "}
            </div>
          </div>
          <div className="card-icon-wrapper">
            <div className="card-icon twitter">
              <i class="fa-brands fa-twitter"></i>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TeamCard;
