import imageUrl from "helpers/imageUrl";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const NFTMInt = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
  


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
        }
      }
    ],



  };

  return (
    <>
      <div className="nft-mint py-5">
        <Link to="/nftmint">
          <button className="golden-btn mb-5 mint">Mint</button>
        </Link>
        <Slider {...settings}>
          <div className="mint-item-wrapper pb-3">
            <div className="mint-item">
              <img src={imageUrl("gallery-1.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-6.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-2.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-3.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-7.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-1.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-6.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-2.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-3.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-7.png")} alt="" />
            </div>
          </div>
        </Slider>
        <Slider {...settings} rtl="true">
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-6.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-3.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-5.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("team-2.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-5.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-6.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-3.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("gallery-5.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("team-2.png")} alt="" />
            </div>
          </div>
          <div className="mint-item-wrapper">
            <div className="mint-item">
              <img src={imageUrl("character-1.png")} alt="" />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default NFTMInt;
