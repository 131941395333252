import React from "react";
import { Col, Row } from "react-bootstrap";
import { UtilityCard } from "./";

const Utilities = () => {
  return (
    <>
      <div className="section-wrapper  container-fluid custom-container">
        <div className="sub-section-wrapper">
          <div className="sub-section-text center">
            <p className="sub-section-title">
              OUR CORE <span className="golden-magazine">UTILITIES</span>
            </p>
            <p className="normal-p">
              Here are a few benefits that you will receive with our membership
              pass…
            </p>
          </div>
        </div>
        <div className="section-body">
          <Row>
            <Col md={6} className="mb-3">
              <UtilityCard
                uImg={"utility-1.png"}
                uTitle={"Exclusive Holders Portal"}
                uDesc={"Perks and discounts on wellbeing experiences & hotels"}
                uDesc2={
                  " You will receive access to our partner brands centered around difference and curated wellbeing experiences that get better overtime."
                }
              />
            </Col>
            <Col md={6} className="mb-3">
              <UtilityCard
                uImg={"utility-2.png"}
                uTitle={"Access TheHighDAO"}
                uDesc={
                  "TheHighDAO will give our customers more control over the direction of this project, where their voice will be heard. You can expect to have voting rights on what brands, events and strategic direction you want The High Club to go."
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <UtilityCard
                uImg={"utility-3.png"}
                uTitle={"Redeem 130g of Cannabis"}
                uDesc={
                  "Owning this membership will allow you to redeem 130 grams of Medical Cannabis from one of our chosen locations."
                }
              />
            </Col>
            <Col md={6} className="mb-3">
              <UtilityCard
                uImg={"utility-4.png"}
                uTitle={"Revenue share from Cannabis Farm"}
                uDesc={
                  "Production of our Thailand operation will begin within Q2 of 2023, all holders of our membership will "
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Utilities;
