import imageUrl from 'helpers/imageUrl'
import React from 'react'

const Footer = () => {


    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

  return <>
    <div className="footer-wrapper container-fluid custom-container">
        <p className="normal-p white">
        natrixholdings 2023 &copy; allrightservices
        </p>
        <div className="footer-icons">

            <div className="footer-icon footer-btn" onClick={scrollToTop}>
                <img src={imageUrl('footer-3.png')} alt="" className='icon'/>
            </div>
        </div>
    </div>
  </>
}

export default Footer